var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo d-flex align-items-center "},[_c('vuexy-logo'),_c('h2',{staticClass:"brand-text text-primary ml-1 mb-0"},[_vm._v(" Starworks Prime ")])],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"src":_vm.imgUrl,"alt":"Register V2","fluid":""}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"lg":"12","md":"6","sm":"8"}},[_c('b-button-group',{staticClass:"my-2 flex w-100 shadow"},[_c('b-button',{attrs:{"variant":"outline-primary","to":{name:'auth-login', query: _vm.$route.query}}},[_vm._v(" Log in ")]),_c('b-button',{staticClass:"bg-primary text-white",attrs:{"variant":"outline-primary"}},[_vm._v(" Register ")])],1),_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Adventure starts here 🚀 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Journey of a thousand miles, starts with a single step! ")]),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.register($event)}}},[_c('b-form-group',{attrs:{"label":"Register as","label-cols":"6","label-cols-lg":"4","label-for":"role"}},[_c('validation-provider',{attrs:{"name":"Role","rules":"required","vid":"role"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"role","options":[{ value: null, text: 'Please select a role', disabled: true }, {text: 'Startup or Student', value: 'user'}, {text: 'Mentor or Partner', value: 'mentor'}]},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Name","label-for":"register-name"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required","vid":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-name","readonly":_vm.resetCodeSent,"state":errors.length > 0 ? false:null,"name":"register-name","placeholder":"John"},model:{value:(_vm.full_name),callback:function ($$v) {_vm.full_name=$$v},expression:"full_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email","vid":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","readonly":_vm.resetCodeSent,"state":errors.length > 0 ? false:null,"name":"register-email","placeholder":"john@example.com"},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Password","label-for":"register-password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","readonly":_vm.resetCodeSent,"state":errors.length > 0 ? false:null,"type":_vm.passwordFieldType,"name":"register-password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.resetCodeSent)?_c('b-form-group',{attrs:{"label":"Confirmation Code","label-for":"confirmation-code"}},[_c('validation-provider',{attrs:{"name":"Confirmation Code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"confirmation-code","state":errors.length > 0 ? false:null,"name":"confirmation-code","placeholder":"012345"},model:{value:(_vm.resetCode),callback:function ($$v) {_vm.resetCode=$$v},expression:"resetCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0])),_c('br')]),(_vm.showResendCode)?_c('small',[_vm._v("Haven't received the verification code? "),_c('b-link',{attrs:{"variant":"link"},on:{"click":function($event){return _vm.resendVerificationCode()}}},[_vm._v("Resend Code ")])],1):_vm._e(),(!_vm.showResendCode)?_c('small',{staticClass:"text-muted"},[_vm._v("You can request new code after 30 seconds.")]):_vm._e()]}}],null,true)})],1):_vm._e(),_c('b-button',{attrs:{"disabled":invalid || _vm.loading,"block":"","type":"submit","variant":"primary"}},[(_vm.loading)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_c('span',{domProps:{"textContent":_vm._s(_vm.resetCodeSent ? 'Confirm Sign Up' : 'Sign Up')}})],1)],1)]}}])}),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Already have an account?")]),_c('b-link',{attrs:{"to":{name:'auth-login', query: _vm.$route.query}}},[_c('span',[_vm._v(" Sign in instead")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }