<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo d-flex align-items-center ">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1 mb-0">
          Starworks Prime
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        class="d-none d-lg-flex align-items-center p-5"
        lg="8"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            :src="imgUrl"
            alt="Register V2"
            fluid
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
        lg="4"
      >
        <b-col
          class="px-xl-2 mx-auto"
          lg="12"
          md="6"
          sm="8"
        >
          <b-button-group class="my-2 flex w-100 shadow">
            <b-button
              variant="outline-primary"
              :to="{name:'auth-login', query: $route.query}"
            >
              Log in
            </b-button>
            <b-button
              class="bg-primary text-white"
              variant="outline-primary"
            >
              Register
            </b-button>
          </b-button-group>

          <b-card-title class="mb-1">
            Adventure starts here 🚀
          </b-card-title>
          <b-card-text class="mb-2">
            Journey of a thousand miles, starts with a single step!
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="registerForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="register"
            >
              <b-form-group
                label="Register as"
                label-cols="6"
                label-cols-lg="4"
                label-for="role"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Role"
                  rules="required"
                  vid="role"
                >
                  <b-form-select
                    id="role"
                    v-model="role"
                    :options="[{ value: null, text: 'Please select a role', disabled: true }, {text: 'Startup or Student', value: 'user'}, {text: 'Mentor or Partner', value: 'mentor'}]"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- first name -->
              <b-form-group
                label="Name"
                label-for="register-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                  vid="name"
                >
                  <b-form-input
                    id="register-name"
                    v-model="full_name"
                    :readonly="resetCodeSent"
                    :state="errors.length > 0 ? false:null"
                    name="register-name"
                    placeholder="John"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group
                label="Email"
                label-for="register-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                  vid="email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="userEmail"
                    :readonly="resetCodeSent"
                    :state="errors.length > 0 ? false:null"
                    name="register-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label="Password"
                label-for="register-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                  vid="password"
                >
                  <b-input-group
                    :class="errors.length > 0 ? 'is-invalid':null"
                    class="input-group-merge"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      :readonly="resetCodeSent"
                      :state="errors.length > 0 ? false:null"
                      :type="passwordFieldType"
                      class="form-control-merge"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                v-if="resetCodeSent"
                label="Confirmation Code"
                label-for="confirmation-code"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirmation Code"
                  rules="required"
                >

                  <b-form-input
                    id="confirmation-code"
                    v-model="resetCode"
                    :state="errors.length > 0 ? false:null"
                    name="confirmation-code"
                    placeholder="012345"
                  />
                  <small class="text-danger">{{ errors[0] }}<br></small>
                  <small v-if="showResendCode">Haven't received the verification code?
                    <b-link
                      variant="link"
                      @click="resendVerificationCode()"
                    >Resend Code
                    </b-link>
                  </small>
                  <small
                    v-if="!showResendCode"
                    class="text-muted"
                  >You can request new code after 30 seconds.</small>
                </validation-provider>
              </b-form-group>

              <b-button
                :disabled="invalid || loading"
                block
                type="submit"
                variant="primary"
              >
                <b-spinner
                  v-if="loading"
                  small
                />
                <span
                  v-text="resetCodeSent ? 'Confirm Sign Up' : 'Sign Up'"
                />
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Already have an account?</span>
            <b-link :to="{name:'auth-login', query: $route.query}">
              <span>&nbsp;Sign in instead</span>
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BButton,
  BButtonGroup,
  BCardText,
  BCardTitle,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BRow,
  BSpinner,
} from 'bootstrap-vue'
import { email, required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { Auth } from 'aws-amplify'

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BButtonGroup,
    BSpinner,
    BForm,
    BCardText,
    BCardTitle,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      full_name: '',
      role: null,
      userEmail: '',
      password: '',
      resetCodeSent: false,
      resetCode: '',
      loading: false,
      showResendCode: false,
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    register() {
      this.loading = true
      if (this.resetCodeSent) {
        return Auth.confirmSignUp(this.userEmail, this.resetCode)
          .then(data => {
            this.loading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: data === 'SUCCESS' ? 'Registration successful' : 'Failed to register',
                icon: data === 'SUCCESS' ? 'CheckIcon' : 'XIcon',
                variant: data === 'SUCCESS' ? 'success' : 'danger',
              },
            })
            if (data === 'SUCCESS') {
              this.$router.replace({ name: 'auth-login', query: { redirect: this.$route.query.redirect } })
            }
          })
          .catch(err => {
            this.loading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: err,
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          })
      }
      return this.$refs.registerForm.validate()
        .then(success => {
          if (success) {
            Auth.signUp({
              username: this.userEmail,
              password: this.password,
              attributes: {
                name: this.full_name,
                'custom:role': this.role,
              },
            })
              .then(({ codeDeliveryDetails }) => {
                this.loading = false
                this.resetCodeSent = true
                setTimeout(() => {
                  this.showResendCode = true
                }, 30000)
                return this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `The confirmation code has been sent on your ${codeDeliveryDetails.AttributeName}`,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
              })
              .catch(err => {
                this.loading = false
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: err,
                    icon: 'XIcon',
                    variant: 'danger',
                  },
                })
                this.$refs.registerForm.setErrors(err.response.data.error)
              })
          }
        })
    },
    resendVerificationCode() {
      this.loading = true
      this.showResendCode = false
      setTimeout(() => {
        this.showResendCode = true
      }, 30000)
      return Auth.resendSignUp(this.userEmail)
        .then(() => {
          this.loading = false
          return this.$toast({
            component: ToastificationContent,
            props: {
              title: 'The confirmation code has been sent again',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(err => {
          this.loading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
